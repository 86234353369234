import React from "react";
import Layout from "../components/layout";
import { Link } from "gatsby";

import SectionHead from "../components/sectionHead";
import colophonStyle from "../styles/colophon.module.scss";
import SEO from "../components/seo";

const Colophon = (props) => {
    return (
        <Layout>
            <div className="content-narrow">
                <SectionHead text="奥付" />
                <div className={colophonStyle.img}></div>
                <ul className={colophonStyle.list}>
                    <li>IT企業で働いていました</li>
                    <li>
                        質問は
                        <a
                            href="https://twitter.com/intent/tweet?screen_name=tamuramble"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            ツイッターで受け付けてます
                        </a>
                    </li>
                    <li>
                        現時点でのブログの
                        <Link to="/archives/setup-v5">技術的内情</Link>
                    </li>
                </ul>
            </div>
            <SEO title="奥付" />
        </Layout>
    );
};

export default Colophon;
