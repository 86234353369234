import React from "react"

import sectionHeadStyle from "../styles/sectionHead.module.scss"

const SectionHead = (props) => {
    return (
        <div className={sectionHeadStyle.root}>
            <h2>{props.text}</h2>
        </div>
    )
}

export default SectionHead
